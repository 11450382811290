
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/payrollsystem",
      function () {
        return require("private-next-pages/payrollsystem/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/payrollsystem"])
      });
    }
  